import httpClient from "@/api/httpClient";

const EVENT = "/api/events";

const createEvent = (_payload) => httpClient.post(EVENT, _payload, {
  headers: {
    "Content-Type": "multipart/form-data",
    "Accept": "*",
  }
})

const editEvent = (payload, id) => httpClient.post(EVENT + "/" + id, payload, {
  headers: {
      "Content-Type": "multipart/form-data",
      "Accept": "*",
  }
})

const getEvents = (_params) => httpClient.get(EVENT, { params: _params });
const getDetail = (_idEvent) => httpClient.get(EVENT + "/" + _idEvent);
const deleteEvent = (_idEvent) => httpClient.delete(EVENT + "/" + _idEvent);
const getActiveEvent = () => httpClient.get(EVENT + "/active");

export { createEvent, getEvents, getDetail, editEvent, deleteEvent, getActiveEvent };
