<template>
  <div>
    <div class="air__utils__heading">
      <h5>
        <span class="mr-3">Total User, Konten Publik & Perpustakaan</span>
      </h5>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-wrap align-items-center">
              <div class="mr-auto">
                <p class="text-uppercase text-dark font-weight-bold mb-1">
                  User
                </p>
                <p class="text-gray-5 mb-0">
                  Semua user aktif
                </p>
              </div>
              <p class="text-primary font-weight-bold font-size-20 mb-0">
                {{ totalData.user }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-wrap align-items-center">
              <div class="mr-auto">
                <p class="text-uppercase text-dark font-weight-bold mb-1">
                  Konten Publik
                </p>
                <p class="text-gray-5 mb-0">
                  Semua konten publik
                </p>
              </div>
              <p class="text-primary font-weight-bold font-size-20 mb-0">
                {{ totalData.public }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-wrap align-items-center">
              <div class="mr-auto">
                <p class="text-uppercase text-dark font-weight-bold mb-1">
                  Konten Perpustakaan
                </p>
                <p class="text-gray-5 mb-0">
                  Semua konten perpustakaan
                </p>
              </div>
              <p class="text-primary font-weight-bold font-size-20 mb-0">
                {{ totalData.library }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getDashboardData} from "@/api/dashboard.api"
import {mapGetters} from "vuex";
export default {
  data() {
    return {
      totalData: '',
      role: '',
    };
  },
  computed:{
    ...mapGetters(['user']),
  },
  mounted() {
    this.getTotalDataReport();
    this.role = this.user.role;
  },
  methods:{
    async getTotalDataReport(){
      try {
        let result = await getDashboardData();
        this.totalData = result.data;
      }catch (e) {
        /**/
      }
    },
  }
};
</script>
