import httpClient from "@/api/httpClient";

const LIST_LIBRARY_CONTENT = "/api/contents/libraries";
const REPORT = "/reports";

const getListLibraryContent = (_params) => httpClient.get(LIST_LIBRARY_CONTENT, {
    params: {..._params, admin: '1'}
})
const getListLibraryContentByCategory = (_params, _id) => httpClient.get(LIST_LIBRARY_CONTENT + "/categories/" + _id + "/contents", {
    params: {..._params, admin: '1'}
})
const createLibrary = (_payload) => httpClient.post(LIST_LIBRARY_CONTENT, _payload, {
    headers: {
        "Content-Type": "multipart/form-data",
        "Accept": "*",
    }
})
const getDetailLibrary = (_id) => httpClient.get(LIST_LIBRARY_CONTENT + "/" + _id, {})
const updateLibrary = (payload, id) =>
    httpClient.post(LIST_LIBRARY_CONTENT + "/" + id, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "*",
        }
    });
const deleteLibrary = (_id) => httpClient.delete(LIST_LIBRARY_CONTENT +"/"+ _id);
const getDetailViewerLibrary = (_id) => httpClient.get(LIST_LIBRARY_CONTENT + "/" + _id + REPORT, {})

export {getDetailLibrary, createLibrary, getListLibraryContentByCategory, getListLibraryContent, deleteLibrary, getDetailViewerLibrary, updateLibrary}
