<template>
  <div v-if="role !== 2">
    <div class="air__utils__heading">
      <h5>
        <span class="mr-3">Konten Publik Terbaru</span>
      </h5>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex flex-column justify-content-center mr-auto">
              <h5 class="mb-0">Konten Publik Terbaru</h5>
            </div>
          </div>
          <div class="card-body">
            <div class="air__utils__scrollTable">
              <a-table
                  :columns="columns"
                  :row-key="record => record.id"
                  :data-source="data"
                  :loading="loading"
              >
                <span slot="image" slot-scope="image, record">
                  <router-link :to="{ path: 'public-content/update/' + record.id }" v-if="image.url || record.video.url">
                    <img v-if="record.video.url" :src="getThumbnailVideo(record.video.url)" onerror="this.src='resources/img-default.png';" class="width-64"/>
                    <img v-else-if="image.url" :src="image.url" class="width-64"/>
                  </router-link>
                  <img src="resources/img-default.png" class="width-64" v-else/>
                </span>
                <span slot="judul" slot-scope="judul, record">
                  <router-link :to="{ path: 'public-content/update/' + record.id }">
                    <p>{{judul}}</p>
                  </router-link>
                </span>
                <span slot="author" slot-scope="author">
                    <p>{{author.fullname}}</p>
                </span>
                <span slot="c_at" slot-scope="c_at">
                    <p>{{c_at}}</p>
                </span>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="air__utils__heading">
      <h5>
        <span class="mr-3">Konten Perpustakaan Terbaru</span>
      </h5>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex flex-column justify-content-center mr-auto">
              <h5 class="mb-0">Konten Perpustakaan Terbaru</h5>
            </div>
          </div>
          <div class="card-body">
            <div class="air__utils__scrollTable">
              <a-table
                  :columns="columns"
                  :row-key="record => record.id"
                  :data-source="libraryData"
                  :loading="libraryLoading"
              >
                <span slot="image" slot-scope="image, record">
                  <router-link :to="{ path: 'library-content/update/' + record.id }" v-if="image.url || record.video.url">
                    <img v-if="record.video.url" :src="getThumbnailVideo(record.video.url)" onerror="this.src='resources/img-default.png';" class="width-64"/>
                    <img v-else-if="image.url" :src="image.url" class="width-64"/>
                  </router-link>
                  <img src="resources/img-default.png" class="width-64" v-else/>
                </span>
                <span slot="judul" slot-scope="judul, record">
                  <router-link :to="{ path: 'library-content/update/' + record.id }">
                    <p>{{judul}}</p>
                  </router-link>
                </span>
                <span slot="author" slot-scope="author">
                    <p>{{author.fullname}}</p>
                </span>
                <span slot="c_at" slot-scope="c_at">
                    <p>{{c_at}}</p>
                </span>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="air__utils__heading">
      <h5>
        <span class="mr-3">Topik Forum Terbaru</span>
      </h5>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex flex-column justify-content-center mr-auto">
              <h5 class="mb-0">Forum Terbaru</h5>
            </div>
          </div>
          <div class="card-body">
            <div class="air__utils__scrollTable">
              <a-table
                  :columns="columns"
                  :row-key="record => record.id"
                  :data-source="forumData"
                  :loading="forumLoading"
              >
                <span slot="image" slot-scope="image, record">
                  <router-link :to="{ path: '/forum/detail/' + record.id }" v-if="image.url">
                  <img :src="image.url" class="width-64"/>
                  </router-link>
                  <img src="resources/img-default.png" class="width-64" v-else/>
                </span>
                <span slot="judul" slot-scope="judul, record">
                  <router-link :to="{ path: '/forum/detail/' + record.id }">
                    <p>{{judul}}</p>
                  </router-link>
                </span>
                <span slot="author" slot-scope="author">
                    <p>{{author.fullname}}</p>
                </span>
                <span slot="c_at" slot-scope="c_at">
                    <p>{{c_at}}</p>
                </span>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {getListPublicContent} from "@/api/publicContent.api";
import {getListLibraryContent} from "@/api/libraryContent.api";
import {getListForum} from "@/api/forum.api";
import {mapGetters} from "vuex";

const columns = [
  {
    title: "Gambar",
    dataIndex: "image",
    scopedSlots: {customRender: 'image'}
  },
  {
    title: "Judul",
    dataIndex: "title",
    scopedSlots: {customRender: 'judul'}
  },
  {
    title: "Author",
    dataIndex: "creator",
    scopedSlots: {
      customRender: "author",
    },
  },
  {
    title: "Tanggal Pembuatan",
    dataIndex: "created_at",
    scopedSlots: {
      customRender: "c_at",
    },
  }
];
export default {
  data() {
    return {
      columns,
      data: [],
      libraryData: [],
      forumData: [],
      loading: false,
      libraryLoading: false,
      forumLoading: false,
      role: '',
    };
  },
  computed :{
    ...mapGetters(['user']),
  },
  mounted() {
    this.fetchData();
    this.role = this.user.role;
  },
  methods: {
    async fetchData(params = {limit: 5}) {
      // console.log(params)
      try {
        this.loading = true;
        let response = await getListPublicContent(params);
        if (response.status == 200) {
          this.data = response.data.results;
          this.loading = false;
        }
        let result = await getListLibraryContent(params);
        if (result) {
          this.libraryData = result.data.results;
          this.libraryLoading = false;
        }
        let responseForum = await getListForum(params);
        if (responseForum) {
          this.forumData = responseForum.data.results;
          this.forumLoading = false;
        }
      } catch (error) {/**/
      }
    },

    getThumbnailVideo(url) {
      const GDRIVE_INDICATOR  = 'https://drive.google.com';
      const GDRIVE_THUMBNAIL  = "https://lh3.googleusercontent.com/d/{id}";
      const YOUTUBE_INDICATOR = 'https://youtu';
      const YOUTUBE_THUMBNAIL = "https://img.youtube.com/vi/{id}/hqdefault.jpg";
      const DEFAULT_IMAGE     = "resources/img-default.png";
      let result = DEFAULT_IMAGE;
      if(typeof url == 'string' && url) {
        if(url.includes(GDRIVE_INDICATOR)) {
          let gdriveIdParams = url.split("/file/d/").pop();
          let additionalParams = gdriveIdParams.split("&").pop();
          let gdriveId = gdriveIdParams.split("/")[0];
          if(additionalParams) {
            gdriveId += "?" + additionalParams
          }
          result = GDRIVE_THUMBNAIL.replace("{id}", gdriveId)
        } else if(url.includes(YOUTUBE_INDICATOR)) {
          let lastParams = url.split("/").pop();
          let youtubeParams = lastParams.split("v=").pop();
          result = YOUTUBE_THUMBNAIL.replace("{id}", youtubeParams)
        }
      }
      return result;
    }
  }
};
</script>
<style scoped>
.width-64 {
  max-width: 85px;
  max-height: fit-content;
  width: auto;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
