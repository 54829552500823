import httpClient from "@/api/httpClient";

const LIST_PUBLIC_CONTENT = "/api/contents/publics";
const REPORT = "/reports";

const getListPublicContent = (_params) => httpClient.get(LIST_PUBLIC_CONTENT, {
    params: {..._params, admin: '1'}
})
const getListPublicContentByCategory = (_params, _id) => httpClient.get(LIST_PUBLIC_CONTENT + "/categories/" + _id + "/contents", {
    params: {..._params, admin: '1'}
})
const createContent = (_payload) => httpClient.post(LIST_PUBLIC_CONTENT, _payload, {
    headers: {
        "Content-Type": "multipart/form-data",
        "Accept": "*",
    }
})
const getDetailContent = (_id) => httpClient.get(LIST_PUBLIC_CONTENT + "/" + _id, {})
const updateContent = (payload, id) =>
    httpClient.post(LIST_PUBLIC_CONTENT + "/" + id, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "*",
        }
    });
const deleteContent = (_id) => httpClient.delete(LIST_PUBLIC_CONTENT +"/"+ _id);
const getDetailViewer = (_id) => httpClient.get(LIST_PUBLIC_CONTENT + "/" + _id + REPORT, {})

export {getListPublicContent, createContent, getDetailContent, updateContent, deleteContent, getDetailViewer, getListPublicContentByCategory}
