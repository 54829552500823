<template>
  <div>
    <Summary/>
    <latest/>
    <a-modal v-model="visible" :footer="null">
      <img :src="url" />
    </a-modal>
  </div>
</template>
<script>
import { getActiveEvent } from "@/api/event.api";
import Summary from "./summary"
import latest from "./latest"
export default {
  components:{
    Summary,
    latest
  },

  mounted() {
    const showEvent = localStorage.getItem("showEvent");
    if(showEvent) {
      this.loadEvent();
    }
  },

  data() {
    return {
      visible: false,
      url: null
    }
  },

  methods: {
    async loadEvent() {
      try {
        const event = await getActiveEvent();
        const data = event.data;
        if(data.image && data.image.url) {
          this.url = data.image.url;
          setTimeout(() => {
            this.visible = true;
            localStorage.removeItem("showEvent");
          }, 1500);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>
